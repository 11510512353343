import _toArray from "/Users/paitony/Works/www/mx/charge_pile/node_modules/@babel/runtime-corejs2/helpers/esm/toArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchRateList, updateRate } from "@/api/finance";
import "@/utils/global.js";
import { transPayState, downloadFun } from "@/utils/util";
import Pagination from "@/components/Pagination";
import { getToken } from "@/utils/cache";
export default {
  name: "orderList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      dataDialog: false,
      rateObj: {
        agent_id: '',
        rates: []
      }
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchRateList(data).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleDownload: function handleDownload() {
      console.log("导出");
    },
    handleEnd: function handleEnd(row) {
      this.dataDialog = true;
      console.log('edit', row);
      var data = Object.assign({}, row);

      var _row$rates = _toArray(row.rates),
          arr = _row$rates.slice(0);

      this.rateObj['rates'] = arr;
      this.rateObj['agent_id'] = row.id;
    },
    handleReturnBtn: function handleReturnBtn() {
      var _this2 = this;

      console.log(this.rateObj);
      var data = {
        agent_id: this.rateObj.agent_id,
        rates: []
      }; // 过滤空的

      var filt = this.rateObj['rates'].filter(function (item) {
        return item.usage && item.rate;
      });
      data['rates'] = filt;
      console.log(data, 'data new rates');
      updateRate(data).then(function (res) {
        _this2.$message({
          type: "success",
          message: "提交成功"
        });

        _this2.getTableData();

        _this2.dataDialog = false;
      }).catch(function (err) {
        _this2.$message({
          type: "warning",
          message: "提交失败,请重新设置"
        });
      });
    },
    handleAdd: function handleAdd() {
      var obj = {
        usage: "",
        rate: ""
      };
      this.rateObj.rates.push(obj);
    },
    handleDelItem: function handleDelItem(row) {
      console.log(row);
      var index = this.rateObj.rates.indexOf(row);
      this.rateObj.rates.splice(index, 1);
    },
    handleAddItem: function handleAddItem(item) {
      var _this3 = this;

      console.log(item);

      if (item.money == "" || item.gives == "") {
        this.$message({
          type: "warning",
          message: "请填写完整"
        });
        return;
      }

      addEnough(item).then(function (res) {
        console.log(res);

        _this3.$message({
          type: "success",
          message: "添加优惠成功"
        });

        _this3.getTableData();
      }).catch(function (err) {
        _this3.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    transPayState: transPayState
  }
};